import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/festl-ruhpolding-2024-preview.jpg'
import presseImage1 from '@images/presse/Festl_Tour_Impressionen_6.jpg'
import presseImage2 from '@images/presse/Festl_Tour_Impressionen_7.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12 xs:col-span-6`

const PressePage = () => {
  const presseImage = '/download/festl-ruhpolding-2024.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Auf geht’s: Bayerisch feiern mit fetten Beats, deftigen Schmankerln und ansteckender Laune!
              </Subtitle>
              <ContentText>
                <p>
                  <strong>
                    Die Alpin FM Festl Tour mit ERDINGER Brauhaus macht Halt im charmanten Ruhpolding
                  </strong>
                </p>
                <p>
                  Erding, 18.07.2024<br />
                  Die Alpin FM Festl Tour mit ERDINGER Brauhaus geht in eine neue Runde.
                  Nachdem Bad Reichenhall richtig abgeliefert und Oberammergau für grandiose Stimmung gesorgt
                  hat, steuert die Tour nun Richtung Ruhpolding.
                </p>
                <p>
                  Am Sonntag, den 28. Juli, wird rund ums urige Café Chiemgau (Hauptstraße 59), ab 14 Uhr die große
                  Party steigen! Mit von der Partie ist die Spitzenklasse bayerischer Mundart-Bands: Max von Milland,
                  Fenzl, Oimara und Monobo Son. Doch nicht nur auf den Ohren wird's heiß, auch beim leiblichen Wohl
                  bleibt kein Wunsch offen. Familie Heigermoser vom Café Chiemgau verwöhnt mit hausgemachten
                  Leckereien, während die Bergwacht Ruhpolding ordentlich den Grill anheizt und für deftige
                  Schmankerl sorgt. Die Wetzstoana und Getränke Gimpl schenken ERDINGER Brauhaus-Spezialitäten
                  und erfrischende alkoholfreie Getränke aus – da bleibt keine Kehle durstig! Rein ins Vergnügen,
                  gemeinsam abfeiern und einen einzigartigen Tag genießen! Der Eintritt ist frei. Alpin FM, die Bands
                  sowie die lokalen Partner und ERDINGER Brauhaus freuen sich über jeden Gast!
                </p>
                <p>
                  Nach dem letzten Stopp in Ruhpolding steigt am Sonntag, den 01. September, das große Finale beim
                  Brass-Sonntag auf dem Herbstfest in Erding. Dabei sein und gemeinsam den Sommer ausklingen lassen!
                </p>
                <p>
                  Homepage: <a href="https://www.erdinger-brauhaus.de/events/" target="_blank">www.erdinger-brauhaus.de/events/</a><br />
                  Instagram: <a href="https://www.instagram.com/erdinger.brauhaus/" target="_blank">@erdinger.brauhaus</a>
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImage} 
                      target="_blank"
                    >
                      <img src={pressePreviewImage} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <ImageSource>Bildquelle: Marcel Laponder / ERDINGER Weißbräu.</ImageSource>
                </div>
              </ContentImageWrapper>

              <br />
              <ContentRow>
                <ContentRowImage>
                  <img src={presseImage1} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                </ContentRowImage>
                <ContentRowImage>
                  <img src={presseImage2} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                </ContentRowImage>
              </ContentRow>
              <p tw="text-center">Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus</p>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
